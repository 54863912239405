import {Entity, World} from "ecsy";
import {
	DealStateComponent,
	GameActionsComponent,
	GameMessagesComponent,
	GameProcessEnvComponent,
	GameStorageTagComponent,
	MoveNowComponent,
	TableOptionsComponent,
	TimerComponent,
	TurnStateComponent,
	ViewStateComponent
} from "../common/components";
import {Side} from "../../enums/Side";
import {actionT} from "../ActionMenuSystem";
import {DealStage} from "../../enums/DealStage";
import {GameMessageDTO} from "../../../net/dto/GameMessageDTO";
import {IGameAction} from "../common/GameAction";
import {DealDiceCalcFn, TilesSortFn} from "../../interfaces/TilesSortFn";
import {ActionMoveType} from "../../enums/ActionMoveType";
import {IRulesOptions} from "../common/GameRulesAbstractSystem";

export class GameStorageWrapper {
	public readonly tableOptions: TableOptions;
	public readonly dealState: IDealState;
	public readonly turnState: TurnState;
	public readonly moveNowState: MoveNowState;
	public readonly timer: Timer;
	public readonly gameProcessEnv: GameProcessEnv;
	private _gameMessages: GameMessages<GameMessageDTO>;
	private _gameActions: GameActions<IGameAction>;
	
	public static wrapEntity(value: Entity): GameStorageWrapper {
		return new GameStorageWrapper(value);
	}
	
	public static create(world: World, name: string) {
		const entity = world.createEntity(name)
			.addComponent(GameStorageTagComponent)
			.addComponent(MoveNowComponent)
			.addComponent(TurnStateComponent)
			.addComponent(DealStateComponent)
			.addComponent(ViewStateComponent)
			.addComponent(TableOptionsComponent)
			.addComponent(TimerComponent)
			.addComponent(GameMessagesComponent)
			.addComponent(GameActionsComponent)
			.addComponent(GameProcessEnvComponent);
		return GameStorageWrapper.wrapEntity(entity);
	}
	
	constructor(public entity: Entity) {
		this.tableOptions = new TableOptions(this.entity);
		this.dealState = new DealState(this.entity);
		this.turnState = new TurnState(this.entity);
		this.moveNowState = new MoveNowState(this.entity);
		this.timer = new Timer(this.entity);
		this.gameProcessEnv = new GameProcessEnv(this.entity);
	}
	
	public dispose(): void {
		this.entity.removeAllComponents();
		this.entity.remove();
	}
	
	public get gameMessages(): GameMessages<GameMessageDTO> {
		if (!this._gameMessages) {
			this._gameMessages = new GameMessages<GameMessageDTO>(this.entity);
		}
		return this._gameMessages;
	}
	
	public get gameActions(): GameActions<IGameAction> {
		if (!this._gameActions) {
			this._gameActions = new GameActions<IGameAction>(this.entity);
		}
		return this._gameActions;
	}
	
	public get imPlayer(): boolean {
		return this.basePlayerId === this.myPlayerId;
	}
	
	public get myPlayerId(): number {
		return this.entity.getComponent(ViewStateComponent).myPlayerId;
	}
	
	public set myPlayerId(playerId) {
		this.entity.getMutableComponent(ViewStateComponent).myPlayerId = playerId;
	}
	
	public get basePlayerId(): number {
		return this.entity.getComponent(ViewStateComponent).basePlayerId;
	}
	
	public set basePlayerId(playerId) {
		this.entity.getMutableComponent(ViewStateComponent).basePlayerId = playerId;
	}
	
	public get viewPlayerId(): number {
		return this.entity.getComponent(ViewStateComponent).viewPlayerId;
	}
	
	public set viewPlayerId(playerId) {
		this.entity.getMutableComponent(ViewStateComponent).viewPlayerId = playerId;
	}
	
	/*public getOptions(): TableOptionsComponent {
		return this.entity.getComponent(TableOptionsComponent);
	}
	
	public getMutableOptions(): TableOptionsComponent {
		return this.entity.getMutableComponent(TableOptionsComponent);
	}*/
	
}

class TableOptions {
	constructor(private entity: Entity) {
	}
	
	private get options() {
		return this.entity.getComponent(TableOptionsComponent);
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent(TableOptionsComponent);
	}
	
	public get rulesSettings(): IRulesOptions {
		return this.options.rulesOptions;
	}
	
	public set rulesSettings(value) {
		this.mutableOptions.rulesOptions = value;
	}
	
	public get actionsSet(): Array<actionT> {
		return this.options.actionsSet;
	}
	
	public set actionsSet(actions) {
		this.mutableOptions.actionsSet = actions;
	}
	
	public get sides(): Side[] {
		return this.options.sides;
	}
	
	public set sides(sides) {
		this.mutableOptions.sides = sides;
	}
	
	public get maxPlayers(): number {
		return this.options.maxPlayers;
	}
	
	public set maxPlayers(length) {
		this.mutableOptions.maxPlayers = length;
	}
	
	public get singleWallLength(): number {
		return this.options.singleWallLength;
	}
	
	public set singleWallLength(length) {
		this.mutableOptions.singleWallLength = length;
	}
	
	public get hasDeadWall(): boolean {
		return this.options.hasDeadWall;
	}
	
	public set hasDeadWall(length) {
		this.mutableOptions.hasDeadWall = length;
	}
	
	/**
	 * Custom sorting function (used to determine the order of the elements)
	 */
	public get concealedSortFn(): TilesSortFn {
		return this.options.concealedSortFn;
	}
	
	public set concealedSortFn(value) {
		this.mutableOptions.concealedSortFn = value;
	}
	
	/**
	 * Determine the tile index to break the wall (game rules specific)
	 */
	public get dealDiceCalcFn(): DealDiceCalcFn {
		return this.options.dealDiceCalcFn;
	}
	
	public set dealDiceCalcFn(value) {
		this.mutableOptions.dealDiceCalcFn = value;
	}
}

class DealState implements IDealState {
	constructor(private entity: Entity) {
	}
	
	private get options() {
		return this.entity.getComponent(DealStateComponent);
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent(DealStateComponent);
	}
	
	public update(dealOptions: { state?: number, dealNum?: number, dealTurn?: number, roundWind?: Side, stage?: DealStage }): void {
		if (Object.values(dealOptions).length > 0) {
			const mc = this.mutableOptions;
			Object.entries(dealOptions)
				.forEach(([key, val]) => mc[key] = val);
		}
	}
	
	public get state(): number {
		return this.options.state;
	}
	
	public set state(value) {
		this.mutableOptions.state = value;
	}
	
	public get stage(): DealStage {
		return this.options.stage;
	}
	
	public set stage(value) {
		console.log("GameStorageWrapper.dealState.stage: set = " + value);
		this.mutableOptions.stage = value;
	}
	
	
	public get dealNum(): number {
		return this.options.dealNum;
	}
	
	public set dealNum(value) {
		this.mutableOptions.dealNum = value;
	}
	
	public get dealTurn(): number {
		return this.options.dealTurn;
	}
	
	public set dealTurn(value) {
		this.mutableOptions.dealTurn = value;
	}
	
	public get roundWind(): Side {
		return this.options.roundWind;
	}
	
	public set roundWind(value) {
		this.mutableOptions.roundWind = value;
	}
	
	public get gameSnapshotProcessing(): boolean {
		return this.options.gameSnapshotProcessing;
	}
	
	public set gameSnapshotProcessing(value) {
		this.mutableOptions.gameSnapshotProcessing = value;
	}
	
}

class TurnState {
	constructor(private entity: Entity) {
	}
	
	/**/
	private get options() {
		return this.entity.getComponent(TurnStateComponent);
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent(TurnStateComponent);
	}
	
	/*public update(dealOptions: { state?: number, dealNum?: number, dealTurn?: number, roundWind?: Side }): void {
		if (Object.values(dealOptions).length > 0) {
			const mc = this.mutableOptions;
			Object.entries(dealOptions)
				.forEach(([key, val]) => mc[key] = val);
		}
	}*/
	
	public get makingMove(): boolean {
		return this.options.makingMove;
	}
	
	public set makingMove(value) {
		console.log("TurnState.makingMove: " + value);
		this.mutableOptions.makingMove = value;
	}
	
	public get waitingForNextMove(): boolean {
		return this.options.waitingForNextMove;
	}
	
	public set waitingForNextMove(value) {
		console.log("TurnState.waitingForNextMove: " + value);
		this.mutableOptions.waitingForNextMove = value;
	}
	
	
}

class MoveNowState {
	constructor(private entity: Entity) {
	}
	
	public toString(): string {
		const opts = this.options;
		return `MoveNowState: {moveNowType: ${opts.moveNowType}, moveNowPlayerId: ${opts.moveNowPlayerId}`;
	}
	
	/**/
	private get options() {
		return this.entity.getComponent(MoveNowComponent);
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent(MoveNowComponent);
	}
	
	/*public update(dealOptions: { state?: number, dealNum?: number, dealTurn?: number, roundWind?: Side }): void {
		if (Object.values(dealOptions).length > 0) {
			const mc = this.mutableOptions;
			Object.entries(dealOptions)
				.forEach(([key, val]) => mc[key] = val);
		}
	}*/
	
	public get moveNowPlayerId(): number {
		return this.options.moveNowPlayerId;
	}
	
	public set moveNowPlayerId(value) {
		this.mutableOptions.moveNowPlayerId = value;
	}
	
	public get moveNowType() {
		return this.options.moveNowType;
	}
	
	public set moveNowType(value) {
		this.mutableOptions.moveNowType = value;
	}
	
	
}


class Timer {
	constructor(private entity: Entity) {
	}
	
	/**/
	private get options() {
		return this.entity.getComponent(TimerComponent);
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent(TimerComponent);
	}
	
	public toString(): string {
		const opts = this.options;
		return `GSW: Timer {active: ${opts.active}, timerSeconds: ${opts.timerSeconds}, dealTurn: ${opts.dealTurn}, timestamp: ${opts.timestamp}}`;
	}
	
	public get active(): boolean {
		return this.options.active;
	}
	
	public set active(value) {
		console.log("Timer.active: " + value);
		this.mutableOptions.active = value;
	}
	
	public setTimer(seconds: number, timestamp: number, dealTurn: number): void {
		const opts = this.mutableOptions;
		opts.timerSeconds = seconds;
		opts.timestamp = timestamp;
		opts.dealTurn = dealTurn;
	}
	
	public get timestamp(): number {
		return this.options.timestamp;
	}
	
	public set timestamp(value) {
		console.log("Timer.timestamp: " + value);
		this.mutableOptions.timestamp = value;
	}
	
	public get timerSeconds(): number {
		return this.options.timerSeconds;
	}
	
	public set timerSeconds(value) {
		console.log("Timer.timerSeconds: " + value);
		this.mutableOptions.timerSeconds = value;
	}
	
	public get dealTurn(): number {
		return this.options.dealTurn;
	}
	
	public set dealTurn(value) {
		console.log("Timer.dealTurn: " + value);
		this.mutableOptions.dealTurn = value;
	}
	
	
}

class GameProcessEnv {
	constructor(private entity: Entity) {
	}
	
	/**/
	private get options() {
		return this.entity.getComponent(GameProcessEnvComponent);
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent(GameProcessEnvComponent);
	}
	
	public toString(): string {
		const opts = this.options;
		return `GSW: Timer {nextTileFromEnd: ${opts.nextTileFromEnd}}`;
	}
	
	public get nextTileFromEnd(): boolean {
		return this.options.nextTileFromEnd;
	}
	
	public set nextTileFromEnd(value) {
		this.mutableOptions.nextTileFromEnd = value;
	}
	
	/** Contains current processing move (pung,kong_self) declared by user. Should be set on MAKE_MOVE and cleared on DEAL_TURN and FROM_SLOT_TO_MELDED */
	public get makeMoveInProgress(): ActionMoveType {
		return this.options.makeMoveInProgress;
	}
	
	public set makeMoveInProgress(value) {
		this.mutableOptions.makeMoveInProgress = value;
	}
	
}


export interface IDealState {
	state: number;
	stage: DealStage;
	gameSnapshotProcessing: boolean;
	dealNum: number;
	dealTurn: number;
	roundWind: Side;
	
	update?(dealOptions: { state?: number, dealNum?: number, dealTurn?: number, roundWind?: Side, stage?: DealStage, gameSnapshotProcessing?: boolean }): void;
}

class GameMessages<T> {
	public pending: MessagesStack<GameMessageDTO>;
	public justProcessed: MessagesStack<GameMessageDTO>;
	public completed: MessagesStack<GameMessageDTO>;
	
	constructor(private entity: Entity) {
		this.pending = new MessagesStack<GameMessageDTO>(this.entity, "pending");
		this.justProcessed = new MessagesStack<GameMessageDTO>(this.entity, "justProcessed");
		this.completed = new MessagesStack<GameMessageDTO>(this.entity, "completed");
	}
	
}

class GameActions<T> {
	public pending: ActionsStack<IGameAction>;
	public justProcessed: ActionsStack<IGameAction>;
	
	constructor(private entity: Entity) {
		this.pending = new ActionsStack<IGameAction>(this.entity, "pending");
		this.justProcessed = new ActionsStack<IGameAction>(this.entity, "justProcessed");
	}
	
}

class MessagesStack<T> {
	constructor(private entity: Entity, private prop: string) {
	}
	
	/**/
	private get propOptions(): T[] {
		return this.entity.getComponent<GameMessagesComponent<T>>(GameMessagesComponent)[this.prop];
	}
	
	private get propMutableOptions() {
		return this.entity.getMutableComponent<GameMessagesComponent<T>>(GameMessagesComponent)[this.prop];
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent<GameMessagesComponent<T>>(GameMessagesComponent);
	}
	
	/**/
	public get isEmpty(): boolean {
		return this.propOptions.length === 0;
	}
	
	public getFirst(): T {
		return this.mutableOptions[this.prop].shift();
	}
	
	public getAll() {
		return [...this.propOptions];
	}
	
	public set(value: T[]) {
		this.mutableOptions[this.prop] = [...value] ?? [];
	}
	
	public add(...args: T[]) {
		return this.propMutableOptions.push(...args);
	}
	
	public clear() {
		this.propMutableOptions.length = 0;
	}
}

class ActionsStack<T> {
	constructor(private entity: Entity, private prop: string) {
	}
	
	/**/
	private get propOptions(): T[] {
		return this.entity.getComponent<GameActionsComponent<T>>(GameActionsComponent)[this.prop];
	}
	
	private get propMutableOptions() {
		return this.entity.getMutableComponent<GameActionsComponent<T>>(GameActionsComponent)[this.prop];
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent<GameActionsComponent<T>>(GameActionsComponent);
	}
	
	/**/
	public get isEmpty(): boolean {
		return this.propOptions.length === 0;
	}
	
	public getFirst(): T {
		return this.mutableOptions[this.prop].shift();
	}
	
	public getAll() {
		return [...this.propOptions];
	}
	
	public set(value: T[]) {
		this.mutableOptions[this.prop] = [...value] ?? [];
	}
	
	public add(...args: T[]) {
		return this.propMutableOptions.push(...args);
	}
	
	public clear() {
		this.propMutableOptions.length = 0;
	}
}





