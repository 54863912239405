<router-outlet></router-outlet>

<div class="icons-container">
    <!--
    <button mat-mini-fab (click)="switchFullscreen()" color="secondary"
            matTooltip="{{'Lobby.MainMenu.FullScreen'|translate}}">
        <i class="material-icons">fullscreen</i>
    </button>
    <button mat-mini-fab (click)="onBugRepClick()" matTooltip="Leave us a comment">
        <i class="material-icons">rate_review</i>
    </button>
    -->
    <!--<i class="material-icons">Test icons container</i>-->
</div>

<app-table-info [gameSettings]="gameStore.joinedGame" [gameWorld]="gameWorld"></app-table-info>
<br>
<div *ngIf="jmIndicators" class="JM-indicators">
    <button mat-mini-fab color="secondary">
        <i class="material-icons">slow_motion_video</i>
    </button>
    <div>
        <div class="base-box-shadowed JM-ind-expanded no-select">
            <span>{{"Bet-Counter"}}: {{jmIndicators.betCounter}}</span>
            <span>{{"JM-Counter"}}: {{jmIndicators.jmCounter}}</span>
            <span>{{"Game.View.DealerCounter_tip"|translate}}: {{jmIndicators.dealerCounter}}</span>
            <span>{{"Game.View.RenchanCounter_tip"|translate}}: {{jmIndicators.renchanCounter}}</span>
            <!--    <span>{{"Game.View.DoraIndicator"|translate}}: {{jmIndicators.}}</span>-->
        </div>
    </div>
</div>

<br>
<div *ngIf="showJackpotInfo" class="indicators">
    <button mat-mini-fab color="secondary">
        <i class="material-icons">emoji_events</i>
    </button>
    <div>
        <div class="base-box-shadowed ind-expanded no-select">
            <b>Jackpot:</b>
            <span>Prize: ${{jackpotInfo?.info?.Prize}}</span>
            <span>Ends In: <app-count-down [seconds]="jackpotInfo?.info?.SecLeft"></app-count-down></span>
            <span>Top score: {{jackpotInfo?.info?.TopScore}}</span>
            <span>Your score: {{jackpotInfo?.info?.UserScoreNeat}}</span>
            <!--            <span>Score: {{jackpotInfo?.info?.UserScore}} / {{jackpotInfo?.info?.TopScore}}</span>-->
            <!--            <span>{{"JM-Counter"}}: {{jmIndicators.jmCounter}}</span>-->
            <!--            <span>{{"Game.View.DealerCounter_tip"|translate}}: {{jmIndicators.dealerCounter}}</span>-->
            <br>
            <b>Participants:</b>
            <div style="display: flex; flex-direction: column;">
                <div *ngFor="let user of jackpotInfo?.participants" style="display: flex; width:100%; gap: 0.3rem;">
                    <span style="flex:1">{{user.UserName}}:</span>
                    <span>{{user.Score}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<br>
<!--
    <div *ngIf="isDebug">
    <app-debug-helper [gameWorld]="gameWorld" matTooltip="[Dev]: Debug Helper"></app-debug-helper>
</div>
-->

