import { Component, HostBinding, HostListener, Input } from "@angular/core";
import { IPlayerResults } from "../../lobby/table/ingame/EndGameDialogComponent";

type winds = "s" | "n" | "e" | "w";

@Component({
	selector: "app-player-game-results",
	templateUrl: "player-game-results.component.html",
	styleUrls: ["player-game-results.component.scss"],
})

export class PlayerGameResultsComponent {
	private _wind: winds = "s";
	private _player: IPlayerResults; // = {} as IPlayerResults;

	@HostBinding("class.compact")
	@Input()
	public cColor = true;

	@HostListener("click")
	onClick(): void {
		this.cColor = !this.cColor;
	}

	@Input()
	public get wind(): winds {
		return this._wind;
	}

	public set wind(value: winds) {
		this._wind = value;
	}

	@Input()
	public get player(): IPlayerResults {
		return this._player;
	}

	public set player(value) {
		this._player = value; // ?? {} as IPlayerResults;
		this.wind = this.player.wind;
	}

	// Returns the points label with doubles and points calculation
	public get pointsLabel(): string {
		// Handle the case where pointsChange is negative
		if (this.pointsChange < 0) {
			const correctedPoints = this.pointsChange + 1200; // Adding 12 doubles (1200 points)
			const doubles = Math.floor(correctedPoints / 100); // Calculate doubles
			const points = correctedPoints % 100; // Calculate remaining points
			return `${doubles} Dbls and ${points} points`;
		}

		// If pointsChange is greater than 600, return the points as they are
		if (this.pointsChange > 600) {
			return `${this.pointsChange} points`;
		}

		// Calculate doubles and points for values less than or equal to 600
		const doubles = Math.floor(this.pointsChange / 100); // Calculate doubles
		const points = this.pointsChange % 100; // Calculate remaining points

		return `${doubles} Dbls and ${points} points`;
	}

	// Getter for pointsChange
	public get pointsChange(): number {
		return this.player?.dealPoints || 0;
	}
}