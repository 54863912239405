// tcProps contains some properties from TEAMCITY_BUILD_PROPERTIES_FILE
export const buildArgs = {
	package: {
		version: "1.0.3"
	},
	tcProps: {
		buildNumber: "202",
		revNumber: "3208"
	}
};
