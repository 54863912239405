<div (mouseleave)="isMouseOver=false" class="Cont">
   <!-- 
    <button mat-mini-fab color="secondary" (click)="isMouseOver=true" (mouseover)="isMouseOver=true">
        <mat-icon>info</mat-icon>
    </button>
    -->
    <!--<div class="iBox gameInfoPopup">
        <div class="irow">
            <span class="iLabel">{{'Lobby.InfoTabs.TableInfo.GameName'|translate}}:</span>
            <span class="iValue">{{gameName}}</span>
        </div>
        <div class="irow" *ngIf="minPoints!==undefined">
            <span class="iLabel"
                  [matTooltip]="'Lobby.HOverlays.GameRules.MP_msg'|translate">{{'Lobby.CreateGame.MinPoints'|translate}}
                :</span>
            <span class="iValue">{{minPoints}}</span>
        </div>
        <div class="irow">
            <span class="iLabel">{{'Game.View.DealNumber'|translate}}</span>
            <span class="iValue">{{dealNumber}}</span>
        </div>
    </div>
    -->
</div>

