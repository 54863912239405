import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TYPES } from "../../../../inversify/inversify.types";
import container from "../../../../inversify/inversify.config";
import { commander } from "../../../../commander/Commander";
import { Commands } from "../../../../commands/Commands";
import { OnlineGameDTO } from "../../../../net/dto/OnlineGameDTO";
import { GameListService } from "../../../../net/service/GameListService";
import { UserStore } from "../../../../store/UserStore";
import { GameMode } from "../../../../game/enums/GameMode";
import { GameType, GameTypeHelper } from "../../../../game/enums/GameType";
import { TableCardAction } from "./table-card.component";
import { AppEventsPipe, AppEventType } from "../../../../game/AppEventsPipe";
import { NavigationService } from "../../../services/navigation.service";
import { NavPage } from "../../../enums/NavPage";
import { environment } from "../../../../environments/environment";
import { GameState } from "../../../../game/enums/GameState";
import { QuickSeatService } from "../../../services/QuickSeatService";
import { OnlineGameHelper } from "../../../../net/helpers/OnlineGameHelper";
import { UITourService } from "../../../services/UITourService";
import { JackpotService } from "../../../../net/service/JackpotService";
import { EntryFeeTier } from "../../../../game/enums/EntryFeeTier";
import { JackpotInfoDTO } from "../../../../net/dto/JackpotInfoDTO";
import { autorun, IReactionDisposer } from "mobx";
import { CurrencyType } from "../../../../game/enums/CurrencyType";
import { WebPages } from "../../../enums/WebPages";
import { TypeRatingDTO } from "src/net/dto/TypeRatingDTO";

@Component({
	selector: "app-games",
	templateUrl: "./games.component.html",
	styleUrls: ["./games.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})


export class GamesComponent implements OnInit, OnDestroy {
	public myActiveGames: Array<OnlineGameDTO> = [];
	public gameTypeId: GameType;
	private appEvents: AppEventsPipe;

	public isDebug = true;
	private navigationRequested = false;
	public userName: string;
	public userRatings: TypeRatingDTO[];
	public ratingType20: TypeRatingDTO | undefined;
	private initialViewportHeight: number = window.innerHeight;
	@Input()
	hasSchoolBox = false;
	@Input()
	hasFunBox = false;
	@Input()
	hasTournamentBox = false;
	@Input()
	myActiveGame: OnlineGameDTO;
	@Input()
	hasJackpotBox = false;
	@Input()
	jackpotInfo: JackpotInfoDTO;
	@Input()
	myBalance: string;
	cashBalance: string;
	reactDisposers: IReactionDisposer[] = [];

	isMobileLayout: boolean = false;

	constructor(
		private router: Router,
		private renderer: Renderer2,
		private route: ActivatedRoute,
		private changeDetectorRef: ChangeDetectorRef,
		private cd: ChangeDetectorRef,
		private navService: NavigationService,
		private qsService: QuickSeatService,
		private tournamentService: UITourService) {
	}

	ngOnInit(): void {
		this.isDebug = environment.isDebug;
		this.userName = this.userStore.info?.Profile?.UserName;
		this.userRatings = this.userStore.info.Rating.Ratings;
		this.ratingType20 = this.userRatings.find(rating => rating.Type === 20);		
		const rgt = this.navService.routerRules?.toUpperCase();

		this.getMyActiveGames().then(games => {
			this.myActiveGames = games;
			this.changeDetectorRef.detectChanges(); // Manually trigger change detection
		  });

		if (!GameTypeHelper.isValidName(this.navService.routerRules)) {
			console.error("GamesComponent.ngOnInit: not valid game type: " + rgt);
			return;
		}
		this.gameTypeId = GameType[rgt];

		this.reactDisposers.push(autorun(() => {
			this.cashBalance = "" + Math.trunc(this.userStore.getBalance(CurrencyType.Usd));
		}));

		this.appEvents = container.get(TYPES.AppEventsPipe);
		this.userName = this.userStore.info?.Profile?.UserName;
		this.checkForActiveGame();
		this.getMyActiveGames().then(games => this.myActiveGames = games);
		switch (this.gameTypeId) {
			case GameType.HK:
			case GameType.AM:
			case GameType.WP:
			case GameType.CO:
			case GameType.RCR:
			case GameType.EMA:
			case GameType.TW:
				this.hasSchoolBox = true;
		}

		this.tournamentService.getOfficialList()
			.then(list => {
				this.hasTournamentBox = list.length > 0;
			})
			.catch();

		commander.executeCommand(Commands.GET_INFO)
			.then(() => {
				this.hasFunBox = this.userStore.info.Account.PlayedGames > 0 || this.userStore.settings.playedAGame;
				console.log(`GamesComponent.: played=${this.userStore.info.Account.PlayedGames} schools=${this.userStore.settings.playedAGame}`);
			})
			.catch();

		// Allow Jackpot: if player has money on their account or played cash before
		if (false && GameTypeHelper.hasJackpot(this.gameTypeId) && (this.userStore.info.Account.RewardPts.Amount > 10 || this.userStore.info.Account.MoneyAmt > 0)) {
			(async () => {
				this.jackpotInfo = await this.getJackpotInfo(this.gameTypeId);
				this.hasJackpotBox = this.jackpotInfo !== null;
				console.log("GamesComponent.jackpot: ", this.jackpotInfo);

				const info = await this.jackpotService.getJackpotInfo(this.userStore.sessionKey);
				console.log("GamesComponent. Info: ", info);
				const infoFull = await this.jackpotService.getJackpotInfoFull(this.userStore.sessionKey, this.gameTypeId, EntryFeeTier.BRONZE);
				console.log("GamesComponent. InfoFULL: ", infoFull);
				const basicInfo = await this.jackpotService.getJackpotBasicInfo(this.userStore.sessionKey, this.gameTypeId, EntryFeeTier.BRONZE);
				console.log("GamesComponent. basicInfo: ", basicInfo);
				const basicInfoFull = await this.jackpotService.getJackpotBasicInfoFull(this.userStore.sessionKey, this.gameTypeId, EntryFeeTier.BRONZE);
				console.log("GamesComponent. basicInfoFull: ", basicInfoFull);
			})();
		}
		else {
			this.hasJackpotBox = false;
		}

	}

	ngOnDestroy(): void {
		this.reactDisposers.forEach(disposer => disposer());
		this.reactDisposers.length = 0;
	}

	@HostListener('window:resize', ['$event'])
	onWindowResize(event: Event) {
		// This should be called whenever the window is resized
		console.log('Window resize event triggered');
		this.handleResize();
	}

	@HostListener('document:focusout', ['$event'])
	onInputBlur(event: FocusEvent) {
	  // Logic to revert UI adjustments when keyboard disappears
		console.log('Input blurred, revert UI adjustments');
		this.handleResize();
	}

	private handleResize() {
		const currentViewportHeight = window.innerHeight;
		console.log(`Current viewport height: ${currentViewportHeight}`);
		// Logic to determine if the keyboard was closed based on height comparison
		if (currentViewportHeight > this.initialViewportHeight + 150) {
			// Assuming the keyboard was closed, adjust layout accordingly
			console.log(`adjustLayoutForClosedKeyboard - Current viewport height: ${currentViewportHeight}`);
			this.adjustLayoutForClosedKeyboard();
		}
		// Update the initialViewportHeight with the current height for future comparisons
		this.initialViewportHeight = currentViewportHeight;
		this.updateViewportScale(1);
	}

	private adjustLayoutForClosedKeyboard() {
		this.updateViewportScale(1);
	  
		// Log the updated scale value to the console
		console.log(`adjustLayoutForClosedKeyboard`);
			this.cd.detectChanges(); // Trigger change detection to apply the changes
		

		console.log('Layout adjusted for closed keyboard.');
	}

	private updateViewportScale(scale: number): void {
		const viewportMetaTag = this.renderer.selectRootElement('meta[name="viewport"]', true);
		this.renderer.setAttribute(
			viewportMetaTag,
			'content',
			`width=device-width, initial-scale=${scale}, maximum-scale=${scale}, user-scalable=no`
		);

		// Log the updated scale value to the console
		console.log(`Viewport scale updated to: ${scale}`);
	}

	canDeactivate(): boolean {
		return this.navigationRequested;
	}

	get haveActiveGames(): boolean {
		return this.myActiveGames.length > 0;
	}

	onTableAction({ action, table }: { action: TableCardAction; table: OnlineGameDTO }) {
		switch (action) {
			case "join":
				this.joinGame(table);
				break;
			default:
				this.appEvents.send(AppEventType.ShowSnack, { message: "Action: " + action });
		}
	}

	switchMode(mode: "normal" | "expert") {
		this.navigationRequested = true;
		this.navService.navigate({ page: mode === "expert" ? NavPage.GamesExpert : NavPage.Games });
	}

	async userSelected(type: PlayType) {
		if (type === "private") {
			this.navService.navigate({ page: NavPage.CreatePrivate });
		}
		else {
			if (type === "fun" && !this.hasFunBox) {
				return;
			}
			const mode = type === "school" ? GameMode.School : GameMode.Fun;
			this.joinQuickGame(this.gameTypeId, mode);
		}
	}

	onReturnToMyGame() {
		this.joinGame(this.myActiveGame);
	}

	private async getMyActiveGames(): Promise<OnlineGameDTO[]> {
		try {
			const myGames = await this.gameListService.getMyGames(this.userStore.sessionKey);
			return myGames.filter(game => game.StateId === GameState.PLAYING)
				.sort((g1, g2) => ((g1.TourId > 0 && g2.TourId === 0) ? 1 : (g1.TourId === 0 && g2.TourId > 0 ? -1 : 0)));
		}
		catch (e) {
			console.error("GamesComponent.getMyActiveGames: " + e);
		}
		return [];
	}

	private async checkForActiveGame() {
		try {
			const myGames = await this.gameListService.getMyGames(this.userStore.sessionKey);
			const avGames = myGames
				.filter(game => (game.StateId === GameState.PLAYING && !OnlineGameHelper.isPractice(game) && !OnlineGameHelper.isSchool(game)))
				// Sort tournament games first
				.sort((g1, g2) => ((g1.TourId > 0 && g2.TourId === 0) ? 1 : (g1.TourId === 0 && g2.TourId > 0 ? -1 : 0)))
				?? [];
			this.myActiveGame = avGames[0];
		}
		catch (e) {
			console.error("GamesComponent.getMyActiveGames: " + e);
		}
	}

	getRankDescription(rank: number | null | undefined): string | number {
		// Check if rank is null, undefined, or not a number (e.g., empty string)
		if (rank === null || rank === undefined) {
			return 999; // Return 999 for unknown or empty rank
		}

		// Implement the logic to convert rank number to a description
		switch (rank) {
			case 1: return 'Beginner';
			case 2: return 'Amateur';
			// Add more cases as needed
			default: return '999'; // Return 'Unknown' for any other rank not covered above
		}
	}


	getBeltImagePath(points: number): string {
		let beltImageName = 'default.png'; // Default image if none of the conditions are met

		if (points < 5) {
			beltImageName = 'white1green.png'; // One Green Stripe on White Belt
		} else if (points < 10) {
			beltImageName = 'white2green.png'; // Two Green Stripes on White Belt
		} else if (points < 25) {
			beltImageName = 'green.png'; // Green Belt
		} else if (points < 50) {
			beltImageName = 'green1brown.png'; // One Brown Stripe on Green Belt
		} else if (points < 75) {
			beltImageName = 'green2brown.png'; // Two Brown Stripes on Green Belt
		} else if (points < 100) {
			beltImageName = 'brown.png'; // Brown Belt
		} else if (points < 150) {
			beltImageName = 'brown1black.png'; // One Black Stripe on Brown Belt
		} else if (points < 200) {
			beltImageName = 'brown2black.png'; // Two Black Stripes on Brown Belt
		} else if (points >= 200) {
			beltImageName = 'black.png'; // Black Belt
		}

		return `assets/img/belts/${beltImageName}`;
	}


	private async joinGame(game: OnlineGameDTO): Promise<boolean> {
		try {
			const gameParams = { gameId: game.GameId, roomId: game.RoomId };
			// TODO: join game and then proceed to table
			await commander.executeCommand(Commands.JOIN_GAME, gameParams);
			this.navigationRequested = true;
			this.navService.navigate({ page: NavPage.Table });
			return true;
		}
		catch (e) {
			console.error("GamesComponent.joinGame: " + e);
			return false;
		}
	}

	private joinQuickGame(gameType: GameType, gameMode: GameMode) {
		console.log(`GamesComponent.joinQuickGame: rules=${gameType} mode=${gameMode}`);
		if (gameMode === GameMode.School) {
			this.qsService.practice(gameType);
		}
		else {
			this.qsService.startSearch(gameType);
		}
	}

	private async getJackpotInfo(gameType: number, tier: EntryFeeTier = EntryFeeTier.BRONZE) {
		try {
			// return await this.jackpotService.getJackpotBasicInfo(this.userStore.sessionKey, GameType.TW, EntryFeeTier.BRONZE);
			const infos = await this.jackpotService.getJackpotInfo(this.userStore.sessionKey);
			if (infos?.length > 0) {
				return infos.find(info => info.GameTypeId === this.gameTypeId && info.TierId === tier);
			}
			else {

			}
		}
		catch (e) {
			console.log("GamesComponent.getJackpotInfo: error -- " + e);
		}
		return null;
	}


	goTournaments() {
		this.navService.navigate({ page: NavPage.Tournaments });
	}

	goJackpot() {
		this.qsService.joinCash(this.gameTypeId);
	}

	onDepositClick() {
		// window.open(`${environment.webRoot}/${WebPages.DEPOSIT}?sid=${this.userStore.sessionKey}&code=Deposit&amount=${moneyPaymentAmt}`, "_blank");
		const moneyPaymentAmt = 10;
		window.open(`${environment.webRoot}/${WebPages.DEPOSIT}?sid=${this.userStore.sessionKey}&code=Deposit&amount=${moneyPaymentAmt}`, "_blank");
	}

	onWithdrawClick() {
		// window.open(`${environment.webRoot}/${WebPages.DEPOSIT}?sid=${this.userStore.sessionKey}&code=Deposit&amount=${moneyPaymentAmt}`, "_blank");
		// const moneyPaymentAmt = 10;
		window.open(`${environment.webRoot}/${WebPages.WITHDRAW}?sid=${this.userStore.sessionKey}`, "_blank");
	}

	onBuyChipsClick() {
		// window.open(`${environment.webRoot}/${WebPages.DEPOSIT}?sid=${this.userStore.sessionKey}&code=Deposit&amount=${moneyPaymentAmt}`, "_blank");
		// const moneyPaymentAmt = 10;
		window.open(`${environment.webRoot}/${WebPages.BUY_CHIPS_AND_COINS}?sid=${this.userStore.sessionKey}`, "_blank");
	}

	isPrivateGame(game: OnlineGameDTO): boolean {
		return OnlineGameHelper.isPrivate(game);
	}



	// *************************************************************************************************************************

	public get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}

	private get gameListService(): GameListService {
		return container.get<GameListService>(TYPES.GameListService);
	}

	private get jackpotService(): JackpotService {
		return container.get<JackpotService>(TYPES.JackpotService);
	}
}

type PlayType = "school" | "fun" | "private";

interface Result<U> {
	result?: U;
	error?: { id?, message: string, details?};
}

/*class DoneResult<T> implements Result<T> {
	constructor(private r: T) {
	}
	get result(): T {
		return this.r;
	}
	/!*
	get error(): { id?, message: string, details? } {
		return this.opts.error;
	}*!/
}*/
/**/