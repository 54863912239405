import {System, SystemConstructor, World} from "ecsy";
import {inject, injectable} from "inversify";
import {PlayerLayoutHelper} from "../helpers/PlayerLayoutHelper";
import {TYPES} from "../../inversify/inversify.types";
import {GameGraphics} from "../GameGraphics";
import {
	ActionMenuDataComponent,
	ActionMenuGuiComponent,
	ActionMenuPrevEntity,
	ActionMenuUIStateComponent,
	DeadWallTagComponent,
	DealStateComponent,
	DraggingTagComponent,
	ForceOpenedTagComponent,
	GameActionsComponent,
	GameMessagesComponent,
	GameProcessEnvComponent,
	GameStorageTagComponent,
	GraphicsComponent,
	InHandIndexComponent,
	InputQueryComponent,
	IsVisibleTagComponent,
	LocationComponent,
	MoveNowComponent,
	PlayerActionsComponent,
	PlayerIdComponent,
	PlayerSoundComponent,
	PlayerStorageTagComponent,
	PlayerStoreComponent,
	PlaySoundComponent,
	PrepareToThrowTagComponent,
	RealSideComponent,
	TableOptionsComponent,
	TileDataComponent,
	TileNodesComponent,
	TilePositionComponent,
	TileRotationComponent,
	TileStoreComponent,
	TileTypeComponent,
	TimeBankComponent,
	TimerComponent,
	TurnStateComponent,
	ViewStateComponent
} from "./common/components";
import {NetGameManager} from "../NetGameManager";
import {GameEventsPipe} from "../GameEventsPipe";
import {GameStore} from "../../store/GameStore";
import {UserStore} from "../../store/UserStore";
import {GameType} from "../enums/GameType";
import {GameMessageDTO} from "../../net/dto/GameMessageDTO";
import {GameRulesAbstractSystem} from "./common/GameRulesAbstractSystem";
import {TexturePack} from "../graphics/TexturePack";
import {ActionMenuSystem} from "./ActionMenuSystem";
import {InputSystem} from "./InputSystem";
import {InputRulesWPSystem} from "./wp/InputRulesWPSystem";
import {GameRulesHKSystem} from "./hk/GameRulesHKSystem";
import {GameRulesWPSystem} from "./wp/GameRulesWPSystem";
import {WPRulesStateComponent, HandIsDeadTag, MarkedForCharlestonTag} from "./wp/components.wp";
import {GameAccessHelper} from "../helpers/GameAccessHelper";
import {InputRulesHKSystem} from "./hk/InputRulesHKSystem";
import {PlayersSystem} from "./PlayersSystem";
import {IExtSystem} from "./IExtSystem";
import {GameStorageWrapper} from "./wrappers/GameStorageWrapper";
import {GameActionsSystem} from "./common/GameActionsSystem";
import {TileCharacterSystem} from "./TileCharacterSystem";
import {TileAnimSystem} from "./TileAnimSystem";
import {TimerSystem} from "./TimerSystem";
import {IGameAction} from "./common/GameAction";
import {SoundsSystem} from "./SoundsSystem";
import {GameRulesCOSystem} from "./co/GameRulesCOSystem";
import {InputRulesCOSystem} from "./co/InputRulesCOSystem";
import {CORulesStateComponent} from "./co/components/CORulesStateComponent";
import {GameRulesJMSystem} from "./jm/GameRulesJMSystem";
import {InputRulesJMSystem} from "./jm/InputRulesJMSystem";
import {JMRulesStateComponent} from "./jm/components/JMRulesStateComponent";
import {ReadyBarTagComponent} from "./jm/components/ReadyBarTagComponent";
import {ReadyBarDeclaredTagComponent} from "./jm/components/ReadyBarDeclaredTagComponent";
import {ActionMenuJMSystem} from "./jm/ActionMenuJMSystem";
import {PlayerKuikaeComponent} from "./jm/components/PlayerKuikaeComponent";
import {LockedTileTagComponent} from "./jm/components/LockedTileTagComponent";
import {GameRulesTWSystem} from "./tw/GameRulesTWSystem";
import {ActionMenuWPSystem} from "./wp/ActionMenuWPSystem";

@injectable()
export class GameWorld {
	
	public world = new World();
	public gameActionsSystem: GameActionsSystem;
	public gameRulesSystem: GameRulesAbstractSystem;
	public gameRulesSystemClass;
	public gameRulesInputSystemClass; // : typeof InputRulesSystem;
	public gameRulesInputSystem: InputRulesWPSystem;
	public gameStorage: GameStorageWrapper;
	private gameType: GameType;
	
	constructor(
		@inject(TYPES.PlayerLayoutHelper) private plh: PlayerLayoutHelper,
		@inject(TYPES.GameGraphics) private gameGraphics: GameGraphics,
		@inject(TYPES.TexturePack) private texturePack: TexturePack,
		@inject(TYPES.NetGameManager) private netGameManager: NetGameManager,
		@inject(TYPES.GameEventsPipe) private gameEvents: GameEventsPipe,
		@inject(TYPES.AppEventsPipe) private appEvents: GameEventsPipe,
		@inject(TYPES.GameStore) private gameStore: GameStore,
		@inject(TYPES.UserStore) private userStore: UserStore,
		@inject(TYPES.GameAccessHelper) private gameAccessHelper: GameAccessHelper,
	) {
		console.log("GameWorld: Create");
		this.init();
	}
	
	private init(): void {
		this.registerComps();
	}
	
	public prepare(gameType: GameType, playerId: number): void {
		this.gameType = gameType;
		this.addGameStoreEntity();
		this.gameStorage.myPlayerId = playerId;
		this.gameStorage.basePlayerId = playerId;
		this.gameStorage.viewPlayerId = playerId;
		
		this.registerSystems();
		this.registerGameRulesSystem(gameType);
	}
	
	// clear on exit
	public reset() {
		// this.gameActionsSystem.removeAllActions();
		// this.world.getSystem(PlayersSystem)
		// 	.unregister();
		// this.world.unregisterSystem(PlayersSystem);
	}
	
	public dispose() {
		// this.world.stop();
		this.unregisterGameRulesSystem(this.gameType);
		this.unregisterSystems();
		this.removeGameStoreEntity();
	}
	
	// *************************************************************************************************************************
	private addGameStoreEntity() {
		if (this.gameStorage) {
			this.removeGameStoreEntity();
		}
		this.gameStorage = GameStorageWrapper.create(this.world, "GameStorage");
	}
	
	private removeGameStoreEntity() {
		if (this.gameStorage) {
			this.gameStorage.dispose();
		}
		this.gameStorage = null;
	}
	
	private unregisterSystemExt = (system: SystemConstructor<System>) => {
		const sys = this.world.getSystem(system) as unknown as IExtSystem;
		sys?.unregister();
		this.world.unregisterSystem(system);
	};
	
	private registerComps() {
		this.world
			.registerComponent(TileDataComponent)
			.registerComponent(TileTypeComponent)
			.registerComponent(TilePositionComponent)
			.registerComponent(TileRotationComponent)
			.registerComponent(PlayerIdComponent)
			.registerComponent(InHandIndexComponent)
			.registerComponent(GraphicsComponent)
			.registerComponent(TileNodesComponent)
			.registerComponent(ActionMenuDataComponent)
			.registerComponent(ActionMenuGuiComponent)
			.registerComponent(ActionMenuPrevEntity)
			.registerComponent(InputQueryComponent)
			.registerComponent(TileStoreComponent)
			.registerComponent(LocationComponent)
			.registerComponent(RealSideComponent)
			.registerComponent(PlayerStoreComponent)
			.registerComponent(MoveNowComponent)
			.registerComponent(PlayerActionsComponent)
			.registerComponent(TurnStateComponent)
			.registerComponent(DealStateComponent)
			.registerComponent(ViewStateComponent)
			.registerComponent(TableOptionsComponent)
			.registerComponent(ActionMenuUIStateComponent)
			.registerComponent(TimeBankComponent)
			.registerComponent(TimerComponent)
			.registerComponent(GameStorageTagComponent)
			.registerComponent(GameProcessEnvComponent)
			.registerComponent(PlayerSoundComponent)
			.registerComponent(PlaySoundComponent)
		;
		this.world
			.registerComponent(IsVisibleTagComponent)
			.registerComponent(DraggingTagComponent)
			.registerComponent(PrepareToThrowTagComponent)
			.registerComponent(ForceOpenedTagComponent)
			.registerComponent(PlayerStorageTagComponent)
			.registerComponent(MarkedForCharlestonTag)
			.registerComponent(HandIsDeadTag)
			.registerComponent(ReadyBarTagComponent)
			.registerComponent(ReadyBarDeclaredTagComponent)
			.registerComponent(LockedTileTagComponent)
		
		;
		this.world
			.registerComponent(WPRulesStateComponent)
			.registerComponent(CORulesStateComponent)
			.registerComponent(JMRulesStateComponent)
		;
		this.world
			.registerComponent(GameMessagesComponent)
			.registerComponent(GameActionsComponent)
			.registerComponent(GameStorageTagComponent)
			.registerComponent(DeadWallTagComponent)
			.registerComponent(PlayerKuikaeComponent)
		;
	}
	
	private registerSystems() {
		console.log("GameWorld.registerSystems");
		this.world.registerSystem(PlayersSystem, {
			priority: -3,
			gameStore: this.gameStore,
			gameGraphics: this.gameGraphics,
			gameEvents: this.gameEvents,
		});
		this.world.registerSystem(TileCharacterSystem, {gameGraphics: this.gameGraphics});
		this.world.registerSystem(TileAnimSystem);
		this.world.registerSystem(GameActionsSystem, {priority: -2});
		// process user input. should be executed before other systems handle inputs
		this.world.registerSystem(InputSystem, {gameGraphics: this.gameGraphics});
		this.world.registerSystem(TimerSystem, {gameEvents: this.gameEvents, gameStore: this.gameStore});
		this.world.registerSystem(SoundsSystem, {
			gameEvents: this.gameEvents,
			userStore: this.userStore,
			gameStore: this.gameStore,
			gameGraphics: this.gameGraphics
		});
		
		this.gameActionsSystem = this.world.getSystem(GameActionsSystem) as GameActionsSystem;
	}
	
	private unregisterSystems() {
		console.log("GameWorld.unregisterSystems");
		this.unregisterSystemExt(PlayersSystem);
		this.unregisterSystemExt(TileCharacterSystem);
		this.unregisterSystemExt(TileAnimSystem);
		this.unregisterSystemExt(GameActionsSystem);
		this.unregisterSystemExt(InputSystem);
		this.unregisterSystemExt(ActionMenuSystem);
		this.unregisterSystemExt(TimerSystem);
		this.unregisterSystemExt(SoundsSystem);
	}
	
	private registerGameRulesSystem(gameType: GameType): void {
		this.unregisterGameRulesSystem(this.gameType);
		
		this.gameRulesSystemClass = this.getRulesClass(gameType);
		this.world.registerSystem(this.gameRulesSystemClass, {
			priority: -1, // First system to execute
			gameGraphics: this.gameGraphics,
			plh: this.plh,
			gameWorld: this,
			gameEvents: this.gameEvents,
			appEvents: this.appEvents,
			gameStore: this.gameStore,
			userStore: this.userStore,
			netManager: this.netGameManager
		});
		this.gameRulesSystem = this.world.getSystem(this.gameRulesSystemClass);
		// this.gameRulesSystem.createTileSet(); // init tiles
		
		this.gameRulesInputSystemClass = this.getInputRulesClass(gameType);
		this.world.registerSystem(this.gameRulesInputSystemClass, {
			plh: this.plh,
			gameGraphics: this.gameGraphics,
			netGameManager: this.netGameManager,
			gameWorld: this,
			appEvents: this.appEvents,
			gameEvents: this.gameEvents,
			gameStore: this.gameStore,
			userStore: this.userStore,
			gameAccessHelper: this.gameAccessHelper,
		});
		this.gameRulesInputSystem = this.world.getSystem(this.gameRulesInputSystemClass);
		
		this.world.registerSystem(this.getActionMenuClass(gameType), {
			gameGraphics: this.gameGraphics,
			texturePack: this.texturePack,
			netGameManager: this.netGameManager,
			appEvents: this.appEvents,
			gameEvents: this.gameEvents,
			gameAccessHelper: this.gameAccessHelper,
		});
		
		// objects are loaded on login
		/*this.world.registerSystem(MovePointerSystem, {
			gameGraphics: this.gameGraphics,
			plh: this.plh,
			gameWorld: this,
			// gameEvents: this.gameEvents,
			// appEvents: this.appEvents,
			gameStore: this.gameStore,
			// userStore: this.userStore,
			texturePack: this.texturePack,
		});*/
		
	}
	
	private unregisterGameRulesSystem(gameType: GameType): void {
		if (!this.gameRulesSystem) {
			return;
		}
		this.gameRulesSystem.unregister();
		this.world.unregisterSystem(this.gameRulesSystemClass);
		this.gameRulesSystem = null;
		this.gameRulesSystemClass = null;
		
		if (!this.gameRulesInputSystem) {
			return;
		}
		this.gameRulesInputSystem.dispose();
		this.world.unregisterSystem(this.gameRulesInputSystemClass);
		this.gameRulesInputSystem = null;
		this.gameRulesInputSystemClass = null;
		
		this.unregisterSystemExt(this.getActionMenuClass(gameType));
	}
	
	// *************************************************************************************************************************
	private getRulesClass(gameType: GameType) {
		switch (gameType) {
			case GameType.CO:
				return GameRulesCOSystem;
			case GameType.WP:
				return GameRulesWPSystem;
			case GameType.RCR:
			case GameType.EMA:
				return GameRulesJMSystem;
			case GameType.TW:
				return GameRulesTWSystem;
			case GameType.HK:
			default:
				return GameRulesHKSystem;
		}
	}
	
	private getInputRulesClass(gameType: GameType) {
		switch (gameType) {
			case GameType.CO:
				return InputRulesCOSystem;
			case GameType.WP:
				return InputRulesWPSystem;
			case GameType.RCR:
			case GameType.EMA:
				return InputRulesJMSystem;
			case GameType.TW:
			case GameType.HK:
			default:
				return InputRulesHKSystem;
		}
	}
	
	private getActionMenuClass(gameType: GameType) {
		switch (gameType) {
			case GameType.WP:
				return ActionMenuWPSystem;
				break;
			case GameType.RCR:
			case GameType.EMA:
				return ActionMenuJMSystem;
			default:
				return ActionMenuSystem;
		}
	}
	
	// *************************************************************************************************************************
	public setViewPlayerId(playerId: number): void {
		if (this.gameStorage) {
			this.gameStorage.viewPlayerId = playerId;
		}
	}
	
	public addAction(action: IGameAction) {
		this.gameActionsSystem.addAction(action);
	}
	
	public addActions(actions: Array<IGameAction>) {
		this.gameActionsSystem.addActions(actions);
	}
	
	public addGameMessages(gameMessages: Array<GameMessageDTO>) {
		this.gameRulesSystem.addGameMessages(gameMessages);
	}
	
	public update(delta, time) {
		// Run all the systems
		this.world.execute(delta, time);
	}
	
}