export const environment = {
	isDebug: true,
	production: false,
	version: require("../../package.json").version,
	endpoint: "http://wp.qa.novowebsoft.com/Mahjong.GameServer.Web/Gateway.aspx",
	webRoot: "http://wp.qa.novowebsoft.com/mahjong.web",
	liteRoot: "https://wp.qa.novowebsoft.com/mahjong.web/lite",

	// i18n: "mahjong.web/lite/assets/i18n/",
	i18n: "assets/i18n/",

	fakeServices: false,
	texturesPath: "assets/game/"
};
