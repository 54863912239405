export class OnlineGameDTO implements IOnlineGame {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.OnlineGame"
	
	public RoomId: number;
	public GameId: number;
	public Name: string;
	public StateId: number;
	public OwnerId: number;
	public OwnerName: string;
	public EastId: number;
	public EastIsHiddenBot: boolean;
	public EastName: string;
	public EastGender: number;
	public EastBelt: number;
	public SouthId: number;
	public SouthIsHiddenBot: boolean;
	public SouthName: string;
	public SouthGender: number;
	public SouthBelt: number;
	public WestId: number;
	public WestIsHiddenBot: boolean;
	public WestName: string;
	public WestGender: number;
	public WestBelt: number;
	public NorthId: number;
	public NorthIsHiddenBot: boolean;
	public NorthName: string;
	public NorthGender: number;
	public NorthBelt: number;
	public Description: string;
	public HostSeat: boolean;
	public ForFriends: boolean;
	public Spectators: boolean;
	public TimeLimit: number;
	public RoundsCount: number;
	public IsRated: boolean;
	public Flowers: boolean;
	public MinBelt: number;
	public ThrownDice: string;
	public PlayerChatId: number;
	public SpecsChatId: number;
	public IsMoney: boolean;
	public EntryFee: number;
	public PrizeFund: number;
	public DeclarePoints: number;
	public ShowWinHandOnly: boolean;
	public ShowGameHelper: boolean;
	public GameTypeId: number;
	public PlayTimeSec: number;
	public WaitForReconnect: boolean;
	public EnableAutoPass: boolean;
	public PracticeLevel: number;
	public TourId: number;
	public IsActive: boolean;
	public IsMyGame: boolean;
	public LastPeak: number;
	public TourRound: number;
	public PtnId: number;
	public ExcludedPtnId: string;
	
	public StateDescriptionId: number;
	public SecondsBeforeFirstMove: number; // 0, StartedAfterFirstMove
	/**number of seconds a replayed game reserve seats for ex-playersQ*/
	public ReplayReserveSeatTimeout: number;
	
	public IsReplay: boolean;
	public ReplayGameId: number;
	public AutoPassMode: number;
	/**
	 * 0 - regular tiles. no transparent (no washizu)
	 * 50 - washizu tiles. 50% of tiles is transparent
	 */
	public TransparencyMode: number;
}

export interface IOnlineGame {
	RoomId?: number;
	GameId?: number;
	Name?: string;
	StateId?: number;
	OwnerId?: number;
	OwnerName?: string;
	EastId?: number;
	EastIsHiddenBot?: boolean;
	EastName?: string;
	EastGender?: number;
	EastBelt?: number;
	SouthId?: number;
	SouthIsHiddenBot?: boolean;
	SouthName?: string;
	SouthGender?: number;
	SouthBelt?: number;
	WestId?: number;
	WestIsHiddenBot?: boolean;
	WestName?: string;
	WestGender?: number;
	WestBelt?: number;
	NorthId?: number;
	NorthIsHiddenBot?: boolean;
	NorthName?: string;
	NorthGender?: number;
	NorthBelt?: number;
	Description?: string;
	HostSeat?: boolean;
	ForFriends?: boolean;
	Spectators?: boolean;
	TimeLimit?: number;
	RoundsCount?: number;
	IsRated?: boolean;
	Flowers?: boolean;
	MinBelt?: number;
	ThrownDice?: string;
	PlayerChatId?: number;
	SpecsChatId?: number;
	IsMoney?: boolean;
	EntryFee?: number;
	PrizeFund?: number;
	DeclarePoints?: number;
	ShowWinHandOnly?: boolean;
	ShowGameHelper?: boolean;
	GameTypeId?: number;
	PlayTimeSec?: number;
	WaitForReconnect?: boolean;
	EnableAutoPass?: boolean;
	PracticeLevel?: number;
	TourId?: number;
	IsActive?: boolean;
	IsMyGame?: boolean;
	LastPeak?: number;
	TourRound?: number;
	PtnId?: number;
	ExcludedPtnId?: string;
	
	StateDescriptionId?: number;
	SecondsBeforeFirstMove?: number; // 0, StartedAfterFirstMove
	/** number of seconds a replayed game reserve seats for ex-playersQ */
	ReplayReserveSeatTimeout?: number;
	
	IsReplay?: boolean;
	ReplayGameId?: number;
	AutoPassMode?: number;
	/**
	 * 0 - regular tiles. no transparent (no washizu)
	 * 50 - washizu tiles. 50% of tiles is transparent
	 */
	TransparencyMode?: number;
}
